<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.add_new_doctor_sign") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'top'"
          >
            <el-form-item :label="columns.doctor_id.title">
              <el-select
                filterable
                clearable
                :placeholder="columns.doctor_id.title"
                size="mini"
                v-model="form.doctor_id"
              >
                <el-option
                  v-for="item in users"
                  :key="item.name + item.id"
                  :label="
                    (item.surname ? item.surname : '') +
                    ' ' +
                    (item.name ? item.name : '') +
                    ' ' +
                    (item.patronymic ? item.patronymic : '')
                  "
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-upload
                class="upload-demo w-100"
                action="/"
                ref="upload"
                :limit="3"
                :on-change="updateImageList"
                :on-remove="handleRemove"
                list-type="picture-card"
                :file-list="imageList"
                :auto-upload="false"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child-new";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawerChild],
  data() {
    return {
      disabled: false,
      imageList: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "doctorSigns/rules",
      users: "users/list",
      model: "doctorSigns/model",
      columns: "doctorSigns/columns",
    }),
  },

  mounted() {
    if (this.users && this.users.length === 0) this.loadUsers();
  },
  methods: {
    ...mapActions({
      loadUsers: "users/index",
      save: "doctorSigns/store",
      empty: "doctorSigns/empty",
    }),
    handleRemove(file) {
      this.imageList = [];
    },
    updateImageList(file) {
      this.imageList = [];
      this.imageList.push({ url: file.url, raw: file.raw });
    },

    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;

          let form = new FormData();
          for (const key in this.imageList) {
            if (this.imageList.hasOwnProperty(key)) {
              const element = this.imageList[key];
              form.append(`images`, element.raw);
            }
          }

          for (var key in this.form) {
            if (this.form[key] != null) {
              form.append(key, this.form[key]);
            } else {
              form.append(key, "");
            }
          }

          this.save(form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.parent().listChanged();
              this.resetForm("form");
              //   if (close == true) {
              this.close();
              //   }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.imageList = [];
    },

    afterClosed() {
      this.empty();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
      // this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
